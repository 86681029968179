import React, {useEffect, useState} from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import {
    Button,
    Typography,
    Box,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Grid
} from "@material-ui/core";
import axios from "axios";
import {MenuHeader} from "../Components/MenuHeader";
import {BackButton} from "../Components/BackButton";
import {SamaggiField} from "../Components/SamaggiField";

const useStyles = makeStyles((theme) => ({
    submit: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(1),
        borderRadius: 40,
        padding: theme.spacing(1.5),
        backgroundColor: "white",
    },
    paper: {
        background: "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
        backdropFilter: "blur(10px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        borderRadius: 30,
        overflow: "visible",
    },
    paperMobile: {
        background: "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
        backdropFilter: "blur(10px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(3, 2),
        borderRadius: 30,
        overflow: "visible"
    },
    samaggiTextOrange: {
        fontSize: "4.5em",
        color: "#d7a02a",
        lineHeight: "1",
        fontFamily: "gotham-bold",
    },
    samaggiTextTransparent: {
        fontSize: "4.5em",
        WebkitTextStrokeColor: "#d7a02a",
        WebkitTextStrokeWidth: "1px",
        color: "rgba(0, 0, 0, 0.2)",
        lineHeight: "1",
    },
    cssLabel: {
        color: "white",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh"
    },

    cssFocused: {}
}));

export const CheckStatusForm: React.VFC<{ loading, setLoading}> = (props) => {
    const classes = useStyles();
    console.log(classes)
    const history = useHistory();

    const [data, setData] = useState(undefined);
    const [showResults, setShowResults] = useState(false);
    const [orderTableData, setOrderTableData] = useState(undefined);
    const [textColour, setTextColour] = useState("#293c7e");
    const [secondaryTextColour, setSecondaryTextColour] = useState("#06693e");
    const [tertiaryTextColour, setTertiaryTextColour] = useState("#4a5579");

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventID = url.pathname.split("/")[1]

        const allEventData = JSON.parse(localStorage.getItem("eventData"));
        const eventData = allEventData[eventID];

        if (eventData !== undefined) {
            setTextColour(eventData["web-text-primary-colour"])
            setSecondaryTextColour(eventData["web-text-secondary-colour"])
            setTertiaryTextColour(eventData["web-text-tertiary-colour"])
        }
    }, [location]);

    let setOpenLoader = props.setLoading;

    async function onSearchOrderDetail(values) {
        console.log(values);
        setOpenLoader(true);
        axios({
            url: process.env.REACT_APP_API_URL,
            method: "post",
            data: {
                query: `
        query Check_status {
          check_status (email: "${values.email}", orderId: "${values.orderId}"){
            statusMessage
            orderId
            email
            status
            details
            error {
              message
            }
            verfCode
            ticketHolders {
              firstName
              lastName
            }
          }
         }
        `,
            },
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
        })
            .then((result) => {
                console.log(result.data.data.check_status);
                const response = result.data.data.check_status;
                if (response.status && response.status === 200) {
                    const verificationCodes = [];
                    const orderStatus = [];
                    const ticketNumber = [];
                    response.verfCode.map((ticketHolder) =>
                        verificationCodes.push(ticketHolder.verfCode)
                    );
                    const message = response.statusMessage;
                    for (let i = 1; i <= response.ticketHolders.length; i++) {
                        orderStatus.push(message);
                        ticketNumber.push(`Ticket #${i}`);
                    }
                    setOrderTableData([
                        { name: "Ticket Number", values: ticketNumber },
                        { name: "Order Status", values: orderStatus },
                        { name: "Verification Code", values: response.verfCode}
                    ]);
                    setShowResults(true);
                    setData(response);
                    setOpenLoader(false);
                } else {
                    alert(response.error.message);
                    setShowResults(false);
                    setData(undefined);
                    setOpenLoader(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function goBack() {
        setShowResults(false);
        setData(undefined);
    }

    return (
        <Box display="flex" flexDirection="column" alignContent="space-between" style={{width: "100%"}}>
            <BackButton onClick={() => history.push({pathname: "./", search: history.location.search})}/>
            <MenuHeader withEventName />
            {!showResults && !data ? (
                <Box display="flex" alignItems="center" flexDirection="column" marginTop="5%">
                    <Box display="flex" flexDirection="row">
                        <Typography variant="h6" style={{ color: textColour, fontWeight: "700", marginBottom: "20px" }}>
                            CHECK ORDER STATUS
                        </Typography>
                    </Box>

                    <Formik
                        initialValues={{
                            orderno: "",
                            email: "",
                        }}
                        onSubmit={(values) => {
                            onSearchOrderDetail(values);
                        }}
                    >
                        <Form>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item xs={12}>
                                    <SamaggiField label={"Email"} name={"email"} placeholder={"Enter your email"} type={"email"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <SamaggiField label={"Order ID"} name={"orderId"} placeholder={"Enter Order ID"} type={"text"}/>
                                </Grid>
                                <Button type="submit" className={classes.submit}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ color: textColour }}
                                    >
                                        CHECK
                                    </Typography>
                                </Button>
                            </Grid>
                        </Form>
                    </Formik>
                </Box>
            ) : (
                <div>
                    <Box display="flex" alignItems="center" flexDirection="column" marginTop="5%">
                        <Box display="flex" flexDirection="row">
                            <Typography variant="subtitle1" style={{ color: textColour, fontWeight: "bold" }}>
                                ORDER STATUS
                            </Typography>
                        </Box>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {orderTableData.map((column, index) => (
                                    <TableCell key={index}>
                                        <Typography variant="subtitle1" style={{ color: textColour, fontWeight: "bold" }}>
                                            {column.name}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderTableData[0].values.map((_, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {orderTableData.map((row, colIndex) => (
                                        <TableCell key={colIndex}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{ color: textColour }}
                                            >
                                                {row.values[rowIndex]}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Button style={{ marginTop: "5%" }} type="submit" className={classes.submit} onClick={() => {goBack();}}>
                        <Typography variant="subtitle2" style={{ color: textColour }}>
                            GO BACK
                        </Typography>
                    </Button>
                </div>
            )}
        </Box>
    );
};
