import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {BrowserRouter, HashRouter, Redirect, Route, Switch} from "react-router-dom";
import { applyMiddleware, createStore } from "redux";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import App from "./App";
import { MenuView } from "./Views/MenuView";
import { rootReducer } from "./reducer";
import { FormView } from "./Views/FormView";
import { SingleMenuView } from "./Views/SingleMenuView";
import { StandingTableMenuView } from "./Views/StandingTableMenuView";
import { SofaTableMenuView } from "./Views/SofaTableMenuView";
import {Main} from "./Main";

const middlewares = [];

const store = createStore(rootReducer, applyMiddleware(...middlewares));

// store.subscribe(() => {
//   const { counter, todolist } = store.getState();
//   localStorage.setItem("todolist", JSON.stringify(todolist));
//   localStorage.setItem("counter", JSON.stringify(counter));
// });

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App>
                <Main>
                    <Switch>
                        <Route path="/:event/single-quantity" component={SingleMenuView} />
                        <Route path="/:event/standing-table-quantity" component={StandingTableMenuView}/>
                        <Route path="/:event/sofa-table-quantity" component={SofaTableMenuView}/>
                        <Route path="/:event/single-ticket" component={FormView} />
                        <Route path="/:event/card-authorisation" component={FormView} />
                        <Route path="/:event/group-tickets" component={FormView} />
                        <Route path="/:event/check-status" component={FormView} />
                        <Route path="/:event/payment" component={FormView} />
                        <Route path="/:event/payment-card" component={FormView} />
                        <Route path="/:event/payment-bank-transfer" component={FormView} />
                        <Route path="/:event/payment-status" component={FormView} />
                        <Route path="/:event/payment-manual" component={FormView} />
                        <Route path="/:event/" component={MenuView} />
                    </Switch>
                </Main>
            </App>
        </BrowserRouter>
    </Provider>,
    document.querySelector("#root")
);
