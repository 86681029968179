import React from "react";
import {NormalButtonStyle} from "../../Styles";

export const NormalButton = (props) => {
    return (
        <NormalButtonStyle type="submit" fullWidth variant="contained" thin={props.thin} {...props}>
            {props.children}
        </NormalButtonStyle>
    )
}
