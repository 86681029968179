import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import {
  CssBaseline,
  Grid,
  useMediaQuery
} from "@mui/material";
import { CheckStatusForm } from "./Forms/CheckStatusForm";
import { CardForm } from "./Forms/CardForm";
import { makeStyles } from "@mui/styles";
import { SingleForm } from "./Forms/SingleForm";
import { GroupForm } from "./Forms/GroupForm";
import { PaymentStatus } from "./Forms/PaymentStatus";
import { CardAuthorisation } from "./Forms/CardAuthorisation";
import {LeftView} from "./Components/LeftView";
import {RightView} from "./Components/RightView";
import {PostixPaper} from "../Styles";
import {useTheme} from "@material-ui/core";
import {PaymentManual} from "./Forms/PaymentManual";
import {MainPaper} from "./Components/MainPaper";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: 10,
    padding: 0
  },
  leftImage: {
    // backgroundImage: "url(https://source.unsplash.com/h5FgjZ0oxNM/1600x900)",
    backgroundImage: "url('/images/banner.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  rightImageMinimize: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: "url('/images/elephant.jpg')",
  },
  logo: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  logoImage: {
    height: "20vh",
    marginTop: "80vh",
  }
}));

export const FormView: React.VFC = () => {
  const classes = useStyles();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const largeMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);

  function getComponent(loading, setLoading) {
    console.log(location.pathname)
    switch (currentPath) {
      case "single-ticket":
        return <SingleForm loading={loading} setLoading={setLoading}/>;
      case "group-tickets":
        return <GroupForm />;
      case "check-status":
        return <CheckStatusForm loading={loading} setLoading={setLoading}/>;
      case "payment-card":
        return <CardForm loading={loading} setLoading={setLoading}/>;
      case "card-authorisation":
        return <CardAuthorisation loading={loading} setLoading={setLoading}/>;
      case "payment-status":
        return <PaymentStatus loading={loading} setLoading={setLoading}/>;
      case "payment-manual":
        return <PaymentManual loading={loading} setLoading={setLoading}/>;
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <LeftView openLoader={loading}>
        <MainPaper isMobile={isMobile} largeMobile={largeMobile}>
          {getComponent(loading, setLoading)}
        </MainPaper>
      </LeftView>
      <RightView/>
    </Grid>
  );
};
