import React, {useEffect, useState} from "react";
import {ContainerWithImage} from "../../Styles";
import {Backdrop, CircularProgress} from "@mui/material";
import {Grid} from "@material-ui/core";
import {useLocation} from "react-router-dom";

export const LeftView: React.FC<{ size?: number, openLoader?: boolean }> = (props) => {
    const [imageURL, setImageURL] = useState("");
    const location = useLocation()

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventID = url.pathname.split("/")[1]

        if (eventID !== "") {
            const allEventData = JSON.parse(localStorage.getItem("eventData"));
            const eventData = allEventData[eventID];

            if (eventData !== undefined) {
                setImageURL(eventData["web-banner-image"])
            }
        }
    }, []);

    return (
        <ContainerWithImage item xs={12} sm={12} md={7} image={imageURL}>
            <Backdrop style={{ color: "#inherit", zIndex: 1 }} open={props.openLoader}>
                <CircularProgress color="warning"/>
            </Backdrop>
            <Grid item xs={8} sm={8} md={5} container wrap="nowrap">
                {props.children}
            </Grid>
        </ContainerWithImage>
    )
}

LeftView.defaultProps = {
    openLoader: false
}
