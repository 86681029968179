export function handleError(error: any, quiet: boolean = false) {
    console.warn(error);

    try {
        if (error.response !== undefined) {
            const err = error.response.data.data.reason;
            if (err !== "Invalid Token" && err !== "Invalid Session") {
                alert(err);
            } else {
                console.log("Invalid Token");
            }
        } else {
            if (!quiet) {
                alert(error.code);
            }
        }
    } catch (e) {
        if (!quiet) {
            alert(error.code);
        }
        console.warn(e);
    }
}
