import React, {useEffect, useState} from "react";
import axios from "axios";
import {Backdrop, CircularProgress} from "@mui/material";
import Grid from "@mui/material/Grid";
import {NormalButton} from "../Components/NormalButton";
import {Colours, SamaggiText} from "../../Styles";

export const SeatMap: React.VFC< {viewSeat: boolean, setViewSeat: any} > = (props) => {
    const [data, setData] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [time, setTime] = useState("")

    useEffect(() => {
        console.log(props.viewSeat)
        if (props.viewSeat && !loaded) {
            setLoaded(false);
            axios.get("https://ywdjj0mi04.execute-api.eu-west-2.amazonaws.com/Prod/live-seat-map").then((res) => {
                setLoaded(true);
                setData(res.data.toString());
                setTime((new Date()).toString());
            })
        }
    })

    return (
        <Backdrop style={{ color: "#inherit", zIndex: 1 }} open={props.viewSeat}>
            <Grid container direction={"column"} alignItems={"center"} spacing={1.5} maxHeight={"100vh"}>
                <Grid item container direction={"column"} alignItems={"center"} spacing={0.5}>
                    <Grid item>
                        {(!loaded && <CircularProgress color="warning"/>)}
                        {(loaded && <img src={data} style={{maxHeight: "80vh"}} alt="Seat Map" />)}
                    </Grid>
                    <Grid item>
                        <SamaggiText fontSize={"14px"} colour={Colours.LightGray}>
                            Last Updated: {time}
                        </SamaggiText>
                    </Grid>
                </Grid>
                <Grid item container direction={"row"} justifyContent={"center"} spacing={1.5}>
                    <Grid item>
                        <NormalButton thin style={{width: "150px"}} onClick={() => {setLoaded(false)}}>Reload</NormalButton>
                    </Grid>
                    <Grid item>
                        <NormalButton thin style={{width: "150px", color: "#d7a02a"}} onClick={() => {setLoaded(false); props.setViewSeat(false)}}>Close</NormalButton>
                    </Grid>
                </Grid>
            </Grid>
        </Backdrop>
    )
}
