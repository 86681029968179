import React, {PropsWithChildren, useEffect, useState} from "react";
import {PostixPaper} from "../../Styles";

export const MainPaper: React.VFC<{isMobile?: true | false, largeMobile?: true | false} & PropsWithChildren<any>> = (props) => {
    const [background, setBackground] = useState("");

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventID = url.pathname.split("/")[1]

        if (eventID !== "") {
            const allEventData = JSON.parse(localStorage.getItem("eventData"));
            const eventData = allEventData[eventID];

            if (eventData !== undefined) {
                setBackground(eventData["web-block-background"])
            }
        }
    }, []);

    return (
        <PostixPaper isMobile={props.isMobile} largeMobile={props.largeMobile} background={background}>
            {props.children}
        </PostixPaper>
    )
}