import React, {useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import {Box, Button, Typography} from "@material-ui/core";
import {TextField} from "@mui/material";
import {NormalButton} from "../Components/NormalButton";
import axios from "axios";
import {useHistory} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AWS from "aws-sdk";
import {v4 as UUIDv4} from "uuid";

export const PaymentManual: React.VFC<{ loading, setLoading }> = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [slipRef, setSlipRef] = useState(UUIDv4().toString().replaceAll("-", "").slice(0, 14))
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");

    const inputRef = useRef(null);

    const history = useHistory();

    async function uploadFile() {
        const bucketName = "postix-payment-slips";
        const bucketRegion = "eu-west-2";
        const IdentityPoolId = "eu-west-2:dffd6de7-031d-4171-b6c8-ab19060e8156";

        AWS.config.update({
            region: bucketRegion,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IdentityPoolId
            })
        });

        if (file === undefined) {
            alert("Please attach a slip file.");
            return
        }

        const re = /(?:\.([^.]+))?$/;
        let fileExt = re.exec(fileName)[1];

        if (fileExt === undefined) {
            fileExt = ".jpg";
        }

        const key = encodeURI(slipRef + UUIDv4().toString().replaceAll("-", "").slice(0, 14) + "." + fileExt);

        const upload = new AWS.S3.ManagedUpload({
            params: {
                Bucket: bucketName,
                Key: key,
                Body: file
            }
        });

        const uploadPromise = upload.promise();

        uploadPromise.then(
            () => saveToDB(bucketName, key),
            function(err) {
                alert(err);
                props.setLoading(false);
                return alert("There was an error uploading your photo: " + err.message);
            }
        );
    }

    function complete() {
        props.setLoading(true);
        uploadFile();
    }

    function saveToDB(bucket, key) {
        const fileLink = "https://" + bucket + ".s3.eu-west-2.amazonaws.com/" + key;

        props.setLoading(true);

        axios({
            url: process.env.REACT_APP_API_URL,
            method: "post",
            data: {
                query: `
        query on_bank_transfer {
          on_bank_transfer(
            price: ${parseFloat(localStorage.getItem("totalPrice")).toFixed(2)}, 
            sessionId: "${localStorage.getItem("sessionId")}",
            billing: {name: "${name}", fullAddress:"${''}", postalCode:"${''}"},
            verification: "${slipRef}",
            fileLink: "${fileLink}",
            email: "${email}"
            ) {
              details
              status
              error {
              message
            }
          }
        }
        `,
            },
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
        }).then((res) => {
            console.log(res)
            props.setLoading(false);
            const data = res.data.data.on_bank_transfer;

            if (data.details == "Saved successfully") {
                history.push("/payment-status");
            } else {
                alert(data.details);
            }
        }).catch((error) => {
            props.setLoading(false);
            alert(error.toString());
        })
    }

    function handleFileChange(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
        }
    }

    return (
        <Grid container direction={"column"} spacing={2}>
            <Box display="flex" alignItems="flex-start">
                <Button onClick={() => history.push("/")}>
                    <ArrowBackIcon style={{ color: "white" }}></ArrowBackIcon>
                    <Typography variant="subtitle2" style={{ color: "white" }}>
                        Cancel Order
                    </Typography>
                </Button>
            </Box>
            <Grid item container direction={"column"} spacing={3}>
                <Grid item container direction={"column"} spacing={1.5}>
                    <Grid item>
                        <Typography variant="h4" style={{ color: "white", fontWeight: "bold" }}>
                            Manual Verification
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="subtitle1" style={{ color: "white", fontWeight: "bold" }}>
                            Total Due {localStorage.getItem("currency")}{parseFloat(localStorage.getItem("totalPrice")).toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container direction={"column"} spacing={1}>
                    <Grid item>
                        <TextField onChange={(e) => {setName(e.target.value)}} fullWidth placeholder={"Name on Payment"} sx={{input: {color: 'white'}}}></TextField>
                    </Grid>

                    <Grid item>
                        <TextField onChange={(e) => {setEmail(e.target.value)}} fullWidth placeholder={"Ticket Email Address"} sx={{input: {color: 'white'}}}></TextField>
                    </Grid>

                    <Grid item>
                        <TextField value={slipRef} onChange={(e) => {setSlipRef(e.target.value)}} fullWidth placeholder={"Reference"} sx={{input: {color: 'white'}}}></TextField>
                    </Grid>
                </Grid>

                <Grid item container>
                    <input ref={inputRef} type="file" accept="image/*" id="file-upload" onChange={(e) => {handleFileChange(e)}} />
                </Grid>

                <Grid item>
                    <NormalButton type="submit" onClick={() => {complete()}}>
                        <Typography variant="subtitle2" style={{ color: "#293c7e" }}>
                            VERIFY
                        </Typography>
                    </NormalButton>
                </Grid>
            </Grid>
        </Grid>
    )
}
