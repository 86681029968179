import React, {useEffect, useState} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {TextField} from "formik-mui";
import {Field, Form, Formik} from "formik";
import {makeStyles} from "@mui/styles";
import {useHistory} from "react-router-dom";
import {Divider, MenuItem, useMediaQuery, useTheme} from "@mui/material";
import {quantity} from "./Forms/Dropdown";
import {MenuHeader} from "./Components/MenuHeader";
import {getTicketData} from "./Global";
import {RightView} from "./Components/RightView";
import {LeftView} from "./Components/LeftView";
import {NormalButton} from "./Components/NormalButton";
import {BackButton} from "./Components/BackButton";
import {MainPaper} from "./Components/MainPaper";

const useStyles = makeStyles((_) => ({
  root: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    padding: 0
  },
  cssLabel: {
    color: "white",
    alignContent: "center",
  },
  cssFocused: {
    color: "white",
    border: "0px solid lightgray",
  },
  subtitle: {
    color: "#434e7c",
    textAlign: "center",
    paddingTop: "3px",
    paddingBottom: "3px"
  }
}));



export const SingleMenuView: React.VFC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const largeMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [, ] = useState(false);
  const [textColour, setTextColour] = useState("#293c7e");
  const [tertiaryColour, setTertiaryColour] = useState("#4a5579");

  function paramsToObject(entries) {
    let result = {}
    entries.forEach((value, key) =>{
      result[key] = value
    })
    return result;
  }

  useEffect(() => {
    const url = new URL(window.location.href);
    const eventID = url.pathname.split("/")[1]

    const allEventData = JSON.parse(localStorage.getItem("eventData"));
    const eventData = allEventData[eventID];

    if (eventData !== undefined) {
      setTextColour(eventData["web-text-primary-colour"])
      setTertiaryColour(eventData["web-text-tertiary-colour"])
    }
  }, [location]);

  let params = new URLSearchParams(history.location.search);

  const stateData: Object = paramsToObject(params);
  const groupData = getTicketData(stateData["selectedTicket"], "groupData");
  const ticketsData = getTicketData()
  console.log(ticketsData);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline/>
      <LeftView>
        <MainPaper isMobile={isMobile} largeMobile={largeMobile}>
          <BackButton onClick={() => history.push({pathname: "./", search: history.location.search})}/>
          <MenuHeader withEventName/>
          <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              marginTop="5%"
              width="100%"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box display="flex" flexDirection="row">
                <Typography variant="h5" style={{color: textColour, textAlign: "center", fontWeight: "bold"}}>
                  {groupData.details.toUpperCase()}
                </Typography>
              </Box>

              <Divider style={{width: "100%", backgroundColor: tertiaryColour, margin: "5%"}}/>

              <Formik
                  initialValues={{
                    singleQuantity: String(groupData.fAmount == 0 ? 1 : groupData.fAmount),
                    ticketType: groupData.tickets.length > 1 ? "X" : groupData.tickets[0].ticketType
                  }}
                  onSubmit={(values, {}) => {
                    localStorage.setItem("selectedTicketType", values.ticketType);
                    localStorage.setItem("singleQuantity", values.singleQuantity);

                    history.push({
                      pathname: "./single-ticket",
                      search: history.location.search
                    })
                  }}
              >
                {({
                    values,
                    isSubmitting
                  }) => (
                    <Form>
                      <Box display="flex" justifyContent="center" flexDirection="column">
                        <Typography variant="subtitle1" style={{color: textColour}}>
                          TICKET TYPE
                        </Typography>
                        <Field required select name="ticketType" variant="outlined" component={TextField}
                               style={{textAlign: "center"}}
                               InputLabelProps={{
                                 classes: {
                                   root: classes.cssLabel,
                                   focused: classes.cssFocused,
                                 },
                                 style: {
                                   color: textColour
                                 }
                               }}
                               InputProps={{
                                 style: {
                                   color: textColour,
                                   marginTop: "10px",
                                   marginBottom: "10px"
                                 }
                               }}
                        >
                          <MenuItem key="X" value="X" disabled>
                            --SELECT--
                          </MenuItem>
                          {groupData["tickets"].sort((a, b) => a.sort - b.sort).map((option) => (
                              <MenuItem key={option.ticketType} value={option.ticketType}>
                                {`${option.name} (${option.currency.replaceAll("GBP", "£")}${option.price * (option.fAmount == 0 ? 1 : option.fAmount)}`}{option.fee == 0 && ")"}{option.fee != 0 && ` + ${option.currency.replaceAll("GBP", "£")}${option.fee} FEE)`}
                              </MenuItem>
                          ))}
                        </Field>

                        {values.ticketType != "X" && (
                            <Box display="flex" justifyContent="center" flexDirection="column">
                              <Typography variant="subtitle1" style={{color: textColour}}>
                                QUANTITY
                              </Typography>
                              <Field required select name="singleQuantity" variant="outlined" component={TextField}
                                     style={{textAlign: "center"}}
                                     InputLabelProps={{
                                       classes: {
                                         root: classes.cssLabel,
                                         focused: classes.cssFocused,
                                       },
                                       style: {
                                         color: textColour
                                       }
                                     }}
                                     InputProps={{
                                       style: {
                                         color: textColour,
                                         marginTop: "10px",
                                         marginBottom: "10px"
                                       }
                                     }}
                              >
                                {quantity(Math.min(ticketsData[values.ticketType].availability, (ticketsData[values.ticketType].fAmount == 0 ? 1 : ticketsData[values.ticketType].fAmount) * 15), ticketsData[values.ticketType].price, ticketsData[values.ticketType].currency.replaceAll("GBP", "£"), ticketsData[values.ticketType].fAmount).map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                ))}
                              </Field>
                            </Box>
                        )}

                        {values.ticketType != "X" && ticketsData[values.ticketType].fee != 0 &&
                            <Typography variant="subtitle1" className={classes.subtitle}>
                              Excl. {ticketsData[values.ticketType].currency.replaceAll("GBP", "£")}{ticketsData[values.ticketType].fee}/ticket
                              Fee
                            </Typography>}

                        {values.ticketType != "X" && ticketsData[values.ticketType].fee == 0 &&
                            <Typography variant="subtitle1" className={classes.subtitle}>
                              Includes Processing and Booking Fee
                            </Typography>}

                        {values.ticketType == "X" && <Typography variant="subtitle1" className={classes.subtitle}>
                          Fees Calculated After Ticket Type Selection
                        </Typography>}

                        <Typography variant="subtitle1" className={classes.subtitle}
                                    style={{color: textColour, fontStyle: "italic", marginBottom: "20px"}}>
                          Any Discount Calculated in the Next Step
                        </Typography>

                        <NormalButton disabled={isSubmitting || values.ticketType == "X"} thin={true}>
                          <Typography variant="subtitle2" style={{color: textColour}}>
                            CONTINUE
                          </Typography>
                        </NormalButton>
                      </Box>
                    </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </MainPaper>
      </LeftView>
      <RightView/>
    </Grid>
  );
};
