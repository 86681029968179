import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import {NoSsr} from "@material-ui/core";

import GothamMedium from "./fonts/Gotham-Medium.woff2";
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  disableGlobal: true
});

interface IProps {
  children?: React.ReactElement | null;
}

const theme = createTheme({
  typography: {
    fontFamily: GothamMedium,
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 400,
    },
  },
});

const App = ({ children }: IProps) => (
  <ThemeProvider theme={theme} >
    <StylesProvider generateClassName={generateClassName}>
      <div>
        <NoSsr>
          <div className="row">{children}</div>
        </NoSsr>
      </div>
    </StylesProvider>
  </ThemeProvider>
);

export default App;
