import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import {
  Button,
  Typography,
  Backdrop,
  Box,
  makeStyles,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Divider,
    TextField
} from "@material-ui/core";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Scroll } from "react-scroll-component";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 40,
    padding: theme.spacing(1.5),
    backgroundColor: "white",
    "&:disabled": {
      backgroundColor: "rgba(57, 57, 57, 0.14)",
    },
  },
  paper: {
    background:
      "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
    backdropFilter: "blur(10px)",
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    borderRadius: 30,
    overflowY: "auto",
    minHeight: "100%",
    height: "80vh",
  },
  paperMobile: {
    background:
      "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
    backdropFilter: "blur(10px)",
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3, 1),
    borderRadius: 30,
    overflowY: "auto",
    minHeight: "100%",
    height: "80vh",
  },
  samaggiTextOrange: {
    fontSize: "4.5em",
    color: "#d7a02a",
    lineHeight: "1",
    fontFamily: "gotham-bold",
  },
  samaggiTextTransparent: {
    fontSize: "4.5em",
    WebkitTextStrokeColor: "#d7a02a",
    WebkitTextStrokeWidth: "1px",
    color: "rgba(0, 0, 0, 0.2)",
    lineHeight: "1",
  },
  cssLabel: {
    color: "white",
  },

  cssFocused: {},
}));

export const GroupForm: React.VFC = () => {
  const classes = useStyles();
  const locationLabel = ["at Tower Millenium Pier ", "London"];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const visibility = isMobile ? "paperMobile" : "paper";
  const history = useHistory();

  const [openLoader, setOpenLoader] = useState(false);
  const [data, setData] = useState(undefined);

  const validatePhoneNum = (phoneNo: string) => {
    var ukPhoneNo = phoneNo.replace(/\s+/g, "");
    if (ukPhoneNo.charAt(0) == "0" && ukPhoneNo.length == 11) {
      return true;
    } else if (ukPhoneNo.includes("+44") && ukPhoneNo.length == 13) {
      return true;
    } else {
      alert("Enter a valid phone no. eg. +44xxxxxxxxxx or 0xxxxxxxxx");
      return false;
    }
  };

  async function onConfirm(values) {
    console.log(values);
    setOpenLoader(true);
    axios({
      url: process.env.REACT_APP_API_URL,
      method: "post",
      data: {
        query: `
        query On_details_confirmation {
          on_details_confirmation (phoneNumber: "${
            values.phoneno
          }", sessionId: "${localStorage.getItem(
          "sessionId"
        )}", ticketHolders: [{firstName: "${values.firstname1}", lastName: "${
          values.lastname1
        }"},{firstName: "${values.firstname1}", lastName: "${
          values.lastname1
        }"},{firstName: "${values.firstname1}", lastName: "${
          values.lastname1
        }"},{firstName: "${values.firstname1}", lastName: "${
          values.lastname1
        }"},{firstName: "${values.firstname1}", lastName: "${
          values.lastname1
        }"},{firstName: "${values.firstname1}", lastName: "${
          values.lastname1
        }"}]){
            details 
            status
            error {
              message
            }
          }
         }
        `,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((result) => {
        console.log(result.data.data.on_details_confirmation);
        if (
          result.data.data.on_details_confirmation.status &&
          result.data.data.on_details_confirmation.status === 200
        ) {
          localStorage.setItem("totalPrice", data.price);
          history.push("/payment-card");
          setOpenLoader(false);
        } else {
          alert(result.data.data.on_details_confirmation.error.message);
          history.push("/");
          setOpenLoader(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function onSelectTicket() {
    setOpenLoader(true);
    axios({
      url: process.env.REACT_APP_API_URL,
      method: "post",
      data: {
        query: `
        query On_quantity {
          on_quantity(qty: 6, type: "${localStorage.getItem(
            "selectedTicketType"
          )}") {
            details
            price
            sessionId
            startingDatetime
            status
          }
         }
        `,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((result) => {
        console.log("");
        setData(result.data.data.on_quantity);
        setOpenLoader(false);
        localStorage.setItem(
          "sessionId",
          result.data.data.on_quantity.sessionId
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    onSelectTicket();
  }, []);

  console.log(localStorage.getItem("sessionId"));
  console.log(data);

  if (data) {
    return (
      <div>
        <Backdrop style={{ color: "#inherit", zIndex: 1 }} open={openLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className={classes[visibility]}>
          <Scroll direction="vertical" height="100%">
            <Box
              display="flex"
              flexDirection="column"
              alignContent="space-between"
            >
              <Box display="flex" alignItems="flex-start">
                <Button onClick={() => history.push("/")}>
                  <ArrowBackIcon style={{ color: "white" }}></ArrowBackIcon>
                  <Typography variant="subtitle2" style={{ color: "white" }}>
                    Back to home
                  </Typography>
                </Button>
              </Box>
              <Box>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                >
                  <Typography
                    component="h4"
                    variant="h4"
                    style={{ color: "white", margin: "2px" }}
                  >
                    SAMAGGI ON THE THAMES BOAT PARTY
                  </Typography>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{ color: "white", margin: "2px" }}
                  >
                    Saturday
                  </Typography>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{ color: "white", margin: "2px" }}
                  >
                    2nd APRIL 2022
                  </Typography>
                  {locationLabel.map((line) => (
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginTop="5%"
                >
                  <Box display="flex" flexDirection="row">
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                    ></Typography>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Typography variant="subtitle1" style={{ color: "white" }}>
                      {localStorage.getItem("selectedTicketType")}
                    </Typography>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                    ></Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {data && (
                      <Typography
                        variant="subtitle1"
                        style={{ color: "white" }}
                      >
                        Price : £{data.price.toFixed(2)}
                      </Typography>
                    )}
                    <Divider
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        margin: "5%",
                      }}
                    />
                    <Typography variant="subtitle2" style={{ color: "white" }}>
                      Please complete the order within
                    </Typography>
                    <Typography variant="subtitle2" style={{ color: "white" }}>
                      30 minutes or the session will expire.
                    </Typography>
                  </Box>

                  <Formik
                    initialValues={{
                      email: "",
                      // email2: "",
                      firstname1: "",
                      lastname1: "",
                      firstname2: "",
                      lastname2: "",
                      phoneno: "",
                      agree: false,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      if (validatePhoneNum(values.phoneno)) {
                        onConfirm(values);
                      } else {
                        setSubmitting(false);
                      }
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <Field
                          required
                          name="phoneno"
                          label="UK Phone no."
                          component={TextField}
                          placeholder="Enter your UK phone no."
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: classes.cssLabel,
                            },
                          }}
                        />

                        {/* <Field
                          required
                          name="email"
                          label="Enter one email to contact"
                          component={TextField}
                          placeholder="Please enter one email"
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: classes.cssLabel,
                            },
                          }}
                        /> */}
                        {/* <Field
                          required
                          name="email2"
                          label="Re-confirm your email"
                          component={TextField}
                          placeholder="Please re-enter your email"
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: classes.cssLabel,
                            },
                          }}
                        /> */}

                        <Box
                          display="flex"
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignContent="center"
                            alignItems="center"
                            width="300px"
                          >
                            <Field
                              required
                              name="firstname1"
                              label="First name"
                              component={TextField}
                              placeholder="Enter your first name"
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.cssLabel,
                                },
                              }}
                            />
                            <Field
                              required
                              name="lastname1"
                              label="Last name"
                              component={TextField}
                              placeholder="Enter your last name"
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.cssLabel,
                                },
                              }}
                            />

                            <Box
                              display="flex"
                              justifyContent="center"
                              my="10%"
                            >
                              <Field required type="checkbox" name="agree" />
                              <Typography
                                variant="subtitle2"
                                style={{ color: "white" }}
                              >
                                Agree to our terms and conditions
                              </Typography>
                            </Box>
                            <Button
                              type="submit"
                              className={classes.submit}
                              disabled={formik.isSubmitting}
                            >
                              <Typography
                                variant="subtitle2"
                                style={{ color: "#293c7e" }}
                              >
                                CONFIRM
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
          </Scroll>
        </div>
      </div>
    );
  } else {
    return (
      <Backdrop style={{ color: "#inherit", zIndex: 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};
