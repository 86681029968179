import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";

export function BackButton(props: { onClick: () => void }) {

    const [textColour, setTextColour] = useState("#293c7e");

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventID = url.pathname.split("/")[1]

        if (eventID !== "") {
            const allEventData = JSON.parse(localStorage.getItem("eventData"));
            const eventData = allEventData[eventID];

            if (eventData !== undefined) {
                setTextColour(eventData["web-text-primary-colour"])
            }
        }
    }, [location]);

    return <Box display="flex" justifyContent="flex-start">
        <Button onClick={props.onClick}>
            <ArrowBackIcon style={{color: textColour}}></ArrowBackIcon>
            <Typography variant="subtitle2" style={{color: textColour}}>
                Back to home
            </Typography>
        </Button>
    </Box>;
}