import React, {useEffect, useState} from "react";
import {Logo, LogoContainer} from "../../Styles";
import {useLocation} from "react-router-dom";

export const LogoFooter: React.FC  = (props) => {

    const [imageURL, setImageURL] = useState("");
    const location = useLocation();

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventID = url.pathname.split("/")[1]

        if (eventID !== "") {
            const allEventData = JSON.parse(localStorage.getItem("eventData"));
            const eventData = allEventData[eventID];

            if (eventData !== undefined) {
                setImageURL(eventData["web-logo-image"])
            }
        }
    }, []);

    return (
        <LogoContainer style={{width: "100%"}}>
            <Logo src={imageURL}/>
        </LogoContainer>
    )
}
