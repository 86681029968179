import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import {
    Backdrop,
    CircularProgress,
    Divider,
    MenuItem,
    useMediaQuery,
    useTheme,
    TextField
} from "@mui/material";
import { TextBanner } from "./Components/TextBanner";
import axios from "axios";

import GothamBold from "./../fonts/Gotham-Bold.woff2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import mapPdf from "../assets/test.pdf";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  leftImage: {
    backgroundImage: "url('/images/banner.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
  },
  paper: {
    background:
      "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
    backdropFilter: "blur(10px)",
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    borderRadius: 30,
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(1),
    width: "20vw",
  },
  paperMobile: {
    background:
      "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
    backdropFilter: "blur(10px)",
    // marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(3, 1),
    borderRadius: 30,
    position: "absolute",
    bottom: theme.spacing(1),
    // left: theme.spacing(1),
    left: "17.5vw",
    width: "65vw",
    height: "70vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  submit: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 40,
    padding: theme.spacing(1.5),
    backgroundColor: "white",
  },
  samaggiTextOrange: {
    fontSize: "4.5em",
    color: "#f7901e",
    lineHeight: "1",
    fontFamily: GothamBold,
  },
  samaggiTextWhite: {
    fontSize: "4.5em",
    color: "white",
    lineHeight: "1",
    WebkitTextStrokeColor: "#f7901e",
    WebkitTextStrokeWidth: "1px",
    fontFamily: GothamBold,
  },
  samaggiTextTransparent: {
    fontSize: "4.5em",
    WebkitTextStrokeColor: "#f7901e",
    WebkitTextStrokeWidth: "1px",
    color: "rgba(0, 0, 0, 0.2)",
    lineHeight: "1",
  },
  samaggiBox: {
    display: "flex",
    flexDirection: "row",
  },
  rightImage: {
    backgroundImage: "url('/images/elephant.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  cssLabel: {
    color: "white",
    alignContent: "center",
  },
  menuPaper: {
    maxHeight: "108px",
  },
  cssFocused: {},
  logo: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  logoImage: {
    height: "20vh",
    marginTop: "80vh",
  }
}));

export const StandingTableMenuView: React.VFC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dateLabel = ["Saturday", "2nd April", "2022"];
  const locationLabel = ["at Tower Millenium Pier ", "London"];
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const visibility = isMobile ? "paperMobile" : "paper";
  const history = useHistory();
  const [openLoader, setOpenLoader] = useState(false);

  const [tableDropdown, setTableDropdown] = useState([]);

  useEffect(() => {
    axios({
      url: process.env.REACT_APP_API_URL,
      method: "post",
      data: {
        query: `
        query GetTickets {
          getTickets {
            details {
              price
              availability
              ticketType
              details
            }
          }
         }
        `,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((result) => {
        console.log(result.data)
        let tables = [];
        if (result.data.data.getTickets.details) {
          let ticketDetails = result.data.data.getTickets.details;
          ticketDetails.map((ticketDetail) => {
            if (
              ticketDetail.details == "TABLE_STANDING" &&
              ticketDetail.availability > 0
            ) {
              tables.push({
                value: ticketDetail.ticketType,
                label:
                  ticketDetail.ticketType.replace("Table ", "") +
                  " (£" +
                  ticketDetail.price +
                  ")",
              });
            }
          });
          setTableDropdown(
            tables.sort(function (a, b) {
              return a.label.localeCompare(b.label, undefined, {
                numeric: true,
                sensitivity: "base",
              });
            })
          );
        } else {
          alert("All tables are sold out");
          history.push("/");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={7} className={classes.leftImage}>
        <Backdrop style={{ color: "#inherit", zIndex: 1 }} open={openLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid xs={8} sm={8} md={5} container wrap="nowrap">
          <Box
            display={{ xs: "block", md: "none" }}
            marginLeft="10%"
            marginTop="5%"
          >
            <Box className={classes.samaggiBox}>
              <Typography className={classes.samaggiTextOrange} noWrap>
                SAMAGGI
              </Typography>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Typography className={classes.samaggiTextTransparent} noWrap>
                ON THE THAMES BOAT PARTY
              </Typography>
            </Box>
            <Box className={classes.samaggiBox}>
              <Typography className={classes.samaggiTextOrange} noWrap>
                ON THE
              </Typography>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Typography className={classes.samaggiTextTransparent} noWrap>
                THAMES BOAT PARTY SAMAGGI ON THE THAMES
              </Typography>
            </Box>
            <Box className={classes.samaggiBox}>
              <Typography className={classes.samaggiTextOrange} noWrap>
                THAMES
              </Typography>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Typography className={classes.samaggiTextTransparent} noWrap>
                BOAT PARTY SAMAGGI ON THE THAMES BOAT PARTY
              </Typography>
            </Box>
            <Box className={classes.samaggiBox}>
              <Typography className={classes.samaggiTextOrange} noWrap>
                BOAT PARTY
              </Typography>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Typography className={classes.samaggiTextTransparent} noWrap>
                SAMAGGI ON THE THAMES BOAT PARTY
              </Typography>
            </Box>
          </Box>
          <div className={classes[visibility]}>
            <Box display="flex" justifyContent="flex-start" mx="3%">
              <Button onClick={() => history.push("/")}>
                <ArrowBackIcon style={{ color: "white" }}></ArrowBackIcon>
                <Typography variant="subtitle2" style={{ color: "white" }}>
                  Back to home
                </Typography>
              </Button>
            </Box>
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
              width="100%"
              marginLeft="20px"
            >
              {dateLabel.map((line) => (
                <Typography
                  component="h4"
                  variant="h4"
                  style={{ color: "white" }}
                  key={line}
                >
                  {line}
                </Typography>
              ))}
              {locationLabel.map((line) => (
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  key={line}
                >
                  {line}
                </Typography>
              ))}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              marginTop="5%"
              width="100%"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box display="flex" flexDirection="row">
                  <Typography variant="subtitle1" style={{ color: "white" }}>
                    S T A N D I N G
                  </Typography>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <Typography variant="subtitle1" style={{ color: "white" }}>
                    T A B L E S
                  </Typography>
                </Box>
                <Divider
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    margin: "5%",
                  }}
                />
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box display="flex" flexDirection="row">
                    <Typography variant="subtitle1" style={{ color: "white" }}>
                      S E L E C T
                    </Typography>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Typography variant="subtitle1" style={{ color: "white" }}>
                      T A B L E
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: "white",
                    }}
                  >
                    * This is a group booking for 6 tickets
                  </Typography>
                </Box>

                <Formik
                  initialValues={{
                    tableName: "",
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    localStorage.setItem(
                      "selectedTicketType",
                      values.tableName
                    );
                    history.push("/group-tickets");
                  }}
                >
                  {(formik) => (
                    <Form>
                      <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <Field
                          required
                          name="tableName"
                          component={TextField}
                          select
                          InputProps={{
                            classes: {
                              root: classes.cssLabel,
                            },
                          }}
                          SelectProps={{
                            MenuProps: {
                              classes: { paper: classes.menuPaper },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            },
                          }}
                        >
                          {tableDropdown.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Field>

                        <a href={mapPdf} target="_blank">
                          <Typography
                            variant="subtitle2"
                            style={{
                              color: "white",
                              textDecorationLine: "underline",
                              marginBottom: "15%",
                            }}
                          >
                            View seating plan
                          </Typography>
                        </a>

                        <Button
                          type="submit"
                          className={classes.submit}
                          disabled={formik.isSubmitting}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ color: "#293c7e" }}
                          >
                            CONFIRM
                          </Typography>
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={false} sm={false} md={5} className={classes.rightImage}>
        <Box display={{ xs: "none", md: "block" }}>
          <TextBanner minimize={false}></TextBanner>
          <Box className={classes.logo}>
            <img src="/images/chang.jpg" className={classes.logoImage}></img>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
