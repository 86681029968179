import { Typography, Grid, CssBaseline, Box } from "@mui/material";
import React, {useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";

import GothamBold from "../../fonts/Gotham-Bold.woff2";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  rightSide: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  rightSideMinimize: {
    display: "contents",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: theme.spacing(1),
  },
  headingMobileContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    alignItems: "flex-end",
  },
  samaggiTextOrange: {
    fontSize: "4.2em !important",
    lineHeight: "1",
    fontFamily: GothamBold,
    fontWeight: "700 !important",
  },
  samaggiTextWhite: {
    fontSize: "4.2em !important",
    color: "white",
    lineHeight: "1",
    WebkitTextStrokeWidth: "2px",
    WebkitFontFeatureSettings: "kern 1",
    fontFamily: GothamBold,
    fontWeight: "700 !important",
  },
  samaggiTextWhiteFill: {
    fontSize: "4.2em !important",
    color: "white",
    lineHeight: "1",
    fontFamily: GothamBold,
    fontWeight: "700 !important",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
}));

export const TextBanner: React.FC<{ minimize: boolean }> = (props) => {
  const classes = useStyles();

  const [textComponents, setTextComponents] = useState([]);
  const [graphicColour, setGraphicColour] = useState("#ffffff");

  useEffect(() => {
    const url = new URL(window.location.href);
    const eventID = url.pathname.split("/")[1]

    if (eventID !== "") {
      const allEventData = JSON.parse(localStorage.getItem("eventData"));
      const eventData = allEventData[eventID];

      if (eventData !== undefined) {
        setTextComponents(eventData["web-aux-text"])
        setGraphicColour(eventData["web-graphic-colour"])
      }
    }
  }, []);


  function rotate(array: Array<String>, amount: number) {
    array = [...array]
    for (let i = 0; i < amount; i++) {
      array.push(array.shift() as String);
    }

    return array;
  }

  return (
    <div>
      <CssBaseline />
      {props.minimize ? (
        <Grid item xs={false} sm={3} md={3} className={classes.rightSideMinimize}>
          <Grid container>
            <Box className={classes.headingMobileContainer}>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                {textComponents.map((comp, index) => (
                  <Box display="none" className={classes.rowContainer} key={`1-${index}-${comp}`}>
                    <Typography className={classes.samaggiTextOrange} key={`2-${index}-${comp}`} noWrap>
                      {comp}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
          <div>
            <Grid item xs={false} sm={5} md={5} className={classes.rightSide}>
              <Box className={classes.headingContainer}>
                {textComponents.map((comp, index) => (
                  <Box className={classes.rowContainer} key={`3-${index}-${comp}`}>
                    <Typography className={classes.samaggiTextOrange} style={{color: graphicColour}} key={`4-${index}-${comp}`} noWrap>
                      {comp}
                    </Typography>
                    <span key={`5-${index}-${comp}`}>&nbsp;&nbsp;</span>
                    <Typography className={classes.samaggiTextWhite} style={{WebkitTextStrokeColor: graphicColour}} key={`6-${index}-${comp}`} noWrap>
                      {(rotate(textComponents, index + 1).join(" ") + " ").repeat(5)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={false} sm={5} md={5} className={classes.rightSide}>
              <Box className={classes.headingContainer}>
                {textComponents.map((comp, index) => (
                  <Box className={classes.rowContainer} key={`7-${index}-${comp}`}>
                    <Typography className={classes.samaggiTextOrange} style={{color: graphicColour}} key={`8-${index}-${comp}`} noWrap>
                      {comp}
                    </Typography>
                    <span key={`9-${index}-${comp}`}>&nbsp;&nbsp;</span>
                    <Typography className={classes.samaggiTextWhiteFill} key={`10-${index}-${comp}`} noWrap>
                      {(rotate(textComponents, index + 1).join(" ") + " ").repeat(5)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          </div>

      )}
    </div>
  );
};
