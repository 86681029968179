
export function getTicketData(ticketType="", from="ticketData") {
    const stringData = localStorage.getItem(from);
    const jsonData = JSON.parse(stringData);

    console.log(`JSON: ${stringData}`)

    if (ticketType == "") {
        return jsonData
    } else {
        return jsonData[ticketType];
    }
}
