export const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export function quantity(maxNum, ticketPrice, ticketCurrency, fAmount) {
  let res = [];
  let step = fAmount == 0 ? 1 : fAmount

  for (let i = step; i <= maxNum; i += step) {
    res.push({
      value: i.toString(),
      label: `${i.toString()} Ticket${i == 1 ? "" : "s"} (${ticketCurrency}${(i * ticketPrice).toFixed(2)})`
    })
  }

  return res;
}


function generateArrayOfYears() {
  const now = new Date().getFullYear();
  const max = now + 20;
  let yr = "";
  const years = [];
  for (let i = now; i <= max; i++) {
    yr = i.toString();
    years.push({
      value: yr.substring(2),
      label: yr,
    });
  }
  return years;
}

export const years = generateArrayOfYears();
