import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {TextBanner} from "./TextBanner";
import {LogoFooter} from "./LogoFooter";
import {ContainerWithImage} from "../../Styles";
import {useLocation} from "react-router-dom";

export const RightView: React.FC<{ size?: number }> = () => {
    const [imageURL, setImageURL] = useState("");

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventID = url.pathname.split("/")[1]

        if (eventID !== "") {
            const allEventData = JSON.parse(localStorage.getItem("eventData"));
            const eventData = allEventData[eventID];

            if (eventData !== undefined) {
                setImageURL(eventData["web-cover-image"])
            }
        }
    }, []);

    return (
        <ContainerWithImage item xs={false} sm={false} md={5} image={imageURL}>
            <Box display={{ xs: "none", md: "block" }}>
                <TextBanner minimize={false}></TextBanner>
                <LogoFooter/>
            </Box>
        </ContainerWithImage>
    )
}

RightView.defaultProps = {
    size: 5
}
